/*
 * @Author: liuzhenkun
 * @Date: 2018-07-10 14:50:07
 * @Last Modified by: liuzhenkun
 * @Last Modified time: 2018-07-10 15:01:52
 */

import VueI18n from 'vue-i18n';

import Vue from 'ssr-common/vue';

import ID from './languages/id.json';

Vue.use(VueI18n);

const i18n = new VueI18n({
    locale: 'id',
    fallbackLocale: 'id',
    messages: {
        'id': ID,
        'id-id': ID
    },
    silentTranslationWarn: true
});

Object.assign(Vue.prototype, {
    $tMap(typeMap, prefix) {
        return Object.values(typeMap).reduce((preVal, currVal) => {
            preVal[currVal] = i18n.t(`${prefix}['${currVal}']`);

            return preVal;
        }, {});
    },

    $tList(typeList) {
        return typeList.map(type => ({
            label: i18n.t(type.label),
            value: type.value
        }));
    }
});

export default i18n;
