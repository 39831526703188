/**
 * @author liuzhenkun
 * @date 2017/12/28-下午5:22
 * @file webview
 */

import i18n from 'moneycan/common/i18n';

export default {
    name: 'webview',

    i18n,

    head() {
        return {};
    }
};
